

var bagDataArray = [

    {
        "id": 1,
        "name": "duck",
        "bgColor": "#f5f0a9",
        "headerText": "Waddle",
        "lensID": "a5beee57-b1e3-49dd-8b7f-48cf527759a2",
    },
    {
        "id": 2,
        "name": "flowers",
        "bgColor": "#ed806d",
        "headerText": "Bloom",
        "lensID": "0199677e-093b-4bb2-b7fa-fa2e904d20ac",
    },
    {
        "id": 3,
        "name": "sleuthosaurus",
        "bgColor": "#6e91cc",
        "headerText": "Detective Dino",
        "lensID": "35047c68-0e5b-4aed-b21e-9177c01256b7",
    },
    {
        "id": 4,
        "name": "slithersnake",
        "bgColor": "#fc3287",
        "headerText": "Slither Snake",
        "lensID": "8a7271f9-0c0a-4e76-9da3-b3612af907e5",
    },
    {
        "id": 5,
        "name": "hearts",
        "bgColor": "#e3a3d5",
        "headerText": "Hearts",
        "lensID": "b1da776e-0048-4b91-ae99-d3e9b656939c",
    }
];

var defaultBag = {
    "id": 0,
    "name": "Default",
    "type": "Default",
    "bgColor": "#ffffff"
};


function Bagdata(searchString) {





    for (let obj of bagDataArray) {
        if (obj.name === searchString) {
            return obj;
        }
    }
    return defaultBag; 
}

export default Bagdata;